.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.scroller {
  /* max-width: 600px; */
  overflow: hidden;
  /* outline: 3px solid lime; */
}

.scroller__inner {
  display: flex;
  gap: 2rem;
}

.scroller {
  /* border: 3px solid lime; */
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 10%,
    white 90%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
}

.scroller .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll 50s linear infinite;
}

@keyframes scroll {
  to {
    /* transform: translate(calc(-50% - 0.5rem)); */
    transform: translate(-50%);
  }
}

.scroller .scroller__inner:hover {
  animation-play-state: paused;
}
