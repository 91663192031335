.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-item.active a {
    font-weight: bold;
  }
  
  .page-item a {
    cursor: pointer;
    color: #000000;
    text-decoration: none;
  }
  