.slider {
  position: static !important;
  display: grid !important;
}
.slider [class^="swiper-button-"] {
  inset: initial;
  margin: 0;
  align-self: center;
}
.slider .swiper-button-prev {
  justify-self: start;
  margin-left: -40px;
}
.slider .swiper-button-next {
  justify-self: end;
  margin-right: -40px;
}
.slider .swiper-pagination {
  inset: initial !important;
  align-self: end;
  justify-self: center;
  margin-bottom: -40px;
}

/* 태블릿 이하에서 화살표 없이 */
@media (max-width: 1024px) {
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
  }
}
